import React from 'react'

export const Header = () => {
    return (
       <header> 
           Task List
       </header>
    )
}

export default Header